//
//home.scss
//

#home {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-home {
  padding: 300px 0px 240px 0px;
  background-color: var(--#{$prefix}primary-text-emphasis);

  @media (max-width: 767.98px) {
    padding: 155px 0px 110px 0px;
    background-color: var(--#{$prefix}primary);
  }

  .bg-overlay {
    background: url("../../../images/home/img-01.png");
    background-size: cover;
    background-position: center;
  }
}

.filter-search-form {
  position: relative;

  i {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    left: 12px;
    z-index: 1;
    color: var(--#{$prefix}primary);
    font-size: 20px;
  }
}

.registration-form {
  position: relative;

  .submit-btn {
    border-radius: 8px;
  }

  .form-control,
  .choices[data-type*=select-one] .choices__inner {
    border-radius: 8px;
  }
}

@media (min-width: 992px) {
  .filter-border {
    &::before {
      content: "";
      border-right: 1px solid var(--#{$prefix}border-color);
      position: absolute;
      right: 4px;
      height: 38px;
      top: 10px;
      z-index: 1;
    }
  }

  .registration-form {
    position: relative;

    .submit-btn {
      border-radius: 0px 8px 8px 0px;
    }

    .form-control {
      border-radius: 8px 0px 0px 8px;
    }

    .choices[data-type*=select-one] .choices__inner {
      border-radius: 0;
    }
  }
}

.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  box-shadow: none !important;
  border: 0;
  font-size: 15px;
  height: 60px;
  padding: 16px 6px 15px 45px;
  border-radius: 0;
}

.treding-keywords {
  li {
    a {
      color: rgba($white, 0.70);
      transition: all 0.5s ease;

      &:hover {
        color: $white;
      }
    }
  }
}

//home-2
.bg-home2 {
  padding: 198px 0 140px 0;
  background-color: rgb(190 187 234 / 10%);
  overflow-x: clip;
  @media (max-width: 767.98px) {
    padding: 160px 0 125px 0;
  }
}

@media (max-width: 991.98px) {
  .home-img {
    height: auto;
    width: 100%;
  }
}

//home-3
.bg-home3 {
  padding: 210px 0 95px 0;
  background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
  overflow-x: clip;
  @media (max-width: 767.98px) {
    padding: 160px 0 90px 0;
  }
}

.homeslider {
  .home-slide-box {
    position: relative;
    .bg-overlay {
      background: linear-gradient(to top, rgba($black, 0.75), transparent);
    }
    .home-slide-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      .slide-para {
        font-family: $font-family-secondary;
      }
    }
  }
  @media (min-width: 992px) {
    width: 200%;

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .swiper-slide {
      width: 60%;
      height: 100%;
    }

    .swiper-slide:nth-child(2n) {
      width: 60%;
      height: 100%;
    }

    .swiper-slide:nth-child(3n) {
      width: 60%;
      height: 100%;
    }
  }
}

.quote-icon {
  .icon {
    position: absolute;
    top: -32px;
    left: 7px;
    z-index: 9;
    font-size: 102px;
    color: $white;
  }
  .icon-2 {
    font-size: 100px;
    position: absolute;
    top: -31px;
    left: 6px;
    z-index: 9;
  }
}

@media (min-width: 768px) {
  .bg-home2,
  .bg-home3 {
    .filter-border {
      &::before {
        content: "";
        border-right: 1px solid var(--#{$prefix}border-color);
        position: absolute;
        right: 4px;
        height: 38px;
        top: 10px;
        z-index: 1;
      }
    }

    .registration-form {
      .submit-btn {
        border-radius: 0px 8px 8px 0px;
      }

      .form-control {
        border-radius: 8px 0px 0px 8px;
      }

      .choices[data-type*=select-one] .choices__inner {
        border-radius: 0px;
      }
    }
  }
}

/*****************dark-mode******************/

body[data-bs-theme="dark"] {
  .bg-home {
    background-color: #373f4a;
  }
}
